// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveModelRow } from '../typings';

const data = [
  {
    "id": "882",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": true,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger7_mat",
          "trim_mat",
          "bigLace_mat",
          "trim_stitching_mat",
          "trim_narrowFit_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ]
      },
      "2": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "back_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "5": {
        "glove": [
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "back_left_stitching_mat",
          "back_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_left_mat",
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "finger6_mat"
        ]
      },
      "8": {
        "glove": [
          "finger2_mat",
          "finger4_mat",
          "finger6_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "back_stitching_mat",
          "embroideryOvalR_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "trim_stitching_mat",
        "shellBack_decoMesh_stitching_mat"
      ],
      "allOnWeb": [
        "back_left_mat",
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "REV204",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": false,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": false,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "200CV",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": true,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": true,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "bigLace_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "trim_stitching_mat",
        "trim_narrowFit_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "proMesh_OvalR_mat",
        "proMesh_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_fingerTips_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "weltingBack",
          "embroideryLogo",
          "weltingBack"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingBack_proMesh",
          "shellBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_fingerTips",
          "shellBack_proMesh_backEdge"
        ],
        "web": [
          "proMesh",
          "proMesh_OvalR"
        ]
      },
      "deco": {
        "glove": [
          "weltingBack_decoMesh",
          "embroideryLogo",
          "shellBack_decoMesh",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_backEdge",
          "shellBack_decoMesh_extra_backEdge",
          "shellBack_decoMesh_extra_stitching",
          "shellBack_decoMesh_OvalR",
          "shellBack_decoMesh_OvalR_backEdge",
          "shellBack_decoMesh_OvalR_extra_backEdge",
          "shellBack_decoMesh_OvalR_extra_mesh",
          "shellBack_decoMesh_OvalR_extra_stitching",
          "shellBack_decoMesh_stitching",
          "shellBack_fingerTips"
        ],
        "web": [
          "decoMesh",
          "decoMesh_stitching"
        ]
      }
    }
  },
  {
    "id": "204HSW",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "bigLace_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    }
  },
  {
    "id": "205FS",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "bigLace_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "200WT",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "200FB",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": true,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "iFingerPadWithHood_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "trim_mat",
          "bigLace_mat",
          "stitching_mat",
          "embroideryOvalR_stitching_mat",
          "iFingerPadWithHood_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "iFingerPadWithHood_stitching_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "iFingerPadWithHood_stitching_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "bigLace_mat",
        "iFingerPadWithHood_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "proMesh_OvalR_mat",
        "proMesh_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_fingerTips_mat",
        "shellBackLaces_mat",
        "shellBackLacesEdge_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "weltingBack",
          "weltingPalm",
          "embroideryLogo"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingBack_proMesh",
          "weltingPalm",
          "shellBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_fingerTips",
          "shellBack_proMesh_backEdge"
        ],
        "web": [
          "proMesh",
          "proMesh_OvalR"
        ]
      }
    }
  },
  {
    "id": "217",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": true,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "bigLace_mat",
          "bigLace_stitching_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat",
          "stitching_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat",
          "bigLacePinky_stitching_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "bigLacePinky_mat",
          "bigLacePinky_stitching_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLace_stitching_mat",
        "bigLacePinky_mat",
        "bigLacePinky_stitching_mat",
        "trim_stitching_mat",
        "trim_narrowFit_stitching_mat",
        "shellBack_decoMesh_stitching_mat",
        "shellBack_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "proMesh_OvalR_mat",
        "proMesh_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_fingerTips_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "finger4",
          "finger5",
          "finger6",
          "weltingBack",
          "weltingPalm",
          "embroideryLogo"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingPalm",
          "weltingBack_proMesh",
          "shellBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_fingerTips",
          "shellBack_proMesh_backEdge"
        ],
        "web": [
          "proMesh_OvalR",
          "proMesh"
        ]
      },
      "deco": {
        "glove": [
          "weltingBack_decoMesh",
          "weltingPalm_decoMesh",
          "embroideryLogo",
          "shellBack_decoMesh",
          "shellBack_decoMesh_stitching",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_backEdge",
          "shellBack_decoMesh_extra_backEdge",
          "shellBack_decoMesh_extra_stitching",
          "shellBack_decoMesh_OvalR",
          "shellBack_decoMesh_OvalR_backEdge",
          "shellBack_decoMesh_OvalR_extra_backEdge",
          "shellBack_decoMesh_OvalR_extra_mesh",
          "shellBack_decoMesh_OvalR_extra_stitching",
          "shellBack_fingerTips"
        ],
        "web": [
          "decoMesh"
        ]
      }
    }
  },
  {
    "id": "302CV",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat",
          "stitching_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "bigLacePinky_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "trim_narrowFit_stitching_mat",
        "bigLacePinky_mat",
        "bigLace_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "proMesh_OvalR_mat",
        "proMesh_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_fingerTips_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "finger4",
          "finger5",
          "finger6",
          "weltingBack",
          "weltingPalm",
          "embroideryLogo"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingPalm",
          "weltingBack_proMesh",
          "shellBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_fingerTips",
          "shellBack_proMesh_backEdge"
        ],
        "web": [
          "proMesh_OvalR",
          "proMesh"
        ]
      },
      "deco": {
        "glove": [
          "weltingBack_decoMesh",
          "weltingPalm_decoMesh",
          "embroideryLogo",
          "shellBack_decoMesh",
          "shellBack_decoMesh_stitching",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_backEdge",
          "shellBack_decoMesh_extra_backEdge",
          "shellBack_decoMesh_extra_stitching",
          "shellBack_decoMesh_OvalR",
          "shellBack_decoMesh_OvalR_backEdge",
          "shellBack_decoMesh_OvalR_extra_backEdge",
          "shellBack_decoMesh_OvalR_extra_mesh",
          "shellBack_decoMesh_OvalR_extra_stitching",
          "shellBack_fingerTips"
        ],
        "web": [
          "decoMesh"
        ]
      }
    }
  },
  {
    "id": "302FB",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": true,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": false,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "iFingerPadWithHood_stitching_mat",
          "iFingerHood_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "5": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "iFingerPadWithHood_stitching_mat",
          "iFingerHood_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_fingerTips_mat",
        "shellBackLaces_mat",
        "shellBackLacesEdge_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "finger5",
          "weltingBack",
          "weltingPalm",
          "embroideryLogo"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "embroideryLogo",
          "shellBack_fingerTips",
          "weltingPalm_proMesh",
          "weltingBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_proMesh",
          "finger5"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      }
    }
  },
  {
    "id": "303",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "3039HSW",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "3039FS",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "REV3039",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": false,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": false,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "315",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": true,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": false,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger10_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat"
        ]
      },
      "2": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "3": {
        "glove": [
          "finger1_mat",
          "finger4_mat",
          "finger6_mat",
          "finger8_mat",
          "finger10_mat",
          "finger10_stitching_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat"
        ],
        "web": [
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "5": {
        "glove": [
          "finger9_mat",
          "finger10_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat",
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "finger6_mat",
          "finger7_mat"
        ]
      },
      "8": {
        "glove": [
          "finger1_mat",
          "finger3_mat",
          "finger4_mat",
          "finger7_mat",
          "finger8_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "finger8_mat",
        "finger9_mat",
        "finger10_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "trim_narrowFit_stitching_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "shellBack_stitching_mat",
        "shellBack_decoMesh_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "trim_mat",
        "trim_narrowFit_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "finger5",
          "finger7",
          "finger9",
          "weltingBack",
          "weltingPalm",
          "embroideryLogo"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingPalm_proMesh",
          "weltingBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_proMesh"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "deco": {
        "glove": [
          "weltingBack_decoMesh",
          "weltingPalm_decoMesh",
          "embroideryLogo",
          "shellBack_decoMesh",
          "shellBack_decoMesh_stitching",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_extra_stitching",
          "shellBack_decoMesh_OvalR",
          "shellBack_decoMesh_OvalR_extra_mesh",
          "shellBack_decoMesh_OvalR_extra_stitching"
        ]
      }
    }
  },
  {
    "id": "314HSW",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "3319",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger10_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "2": {
        "glove": [
          "finger1_mat",
          "finger2_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "3": {
        "glove": [
          "finger1_mat",
          "finger4_mat",
          "finger6_mat",
          "finger8_mat",
          "finger10_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "5": {
        "glove": [
          "finger9_mat",
          "finger10_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat",
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "finger6_mat",
          "finger7_mat"
        ]
      },
      "8": {
        "glove": [
          "finger1_mat",
          "finger3_mat",
          "finger4_mat",
          "finger7_mat",
          "finger8_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "finger8_mat",
        "finger9_mat",
        "finger10_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "trim_mat",
        "trim_narrowFit_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "502",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "bigLace_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "lining_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "504",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_stitching_mat",
          "trim_narrowFit_mat",
          "trim_narrowFit_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "trim_narrowFit_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "trim_narrowFit_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "bigLace_mat",
        "weltingBack_mat",
        "weltingPalm_mat"
      ]
    }
  },
  {
    "id": "600",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": true,
    "hasShellBackLaces": true,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBackLaces_mat",
        "shellBack_fingerTips_mat"
      ],
      "leather": {
        "glove": [
          "finger2",
          "finger3",
          "weltingBack",
          "weltingPalm"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "pro": {
        "glove": [
          "weltingBack_proMesh",
          "weltingPalm",
          "shellBack_proMesh",
          "shellBack_proMesh_stitching",
          "shellBack_fingerTips"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      }
    }
  },
  {
    "id": "6019HSW",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": true,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "6019FS",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": true,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": true,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger5_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "JD",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger8_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "5": {
        "glove": [
          "finger8_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger8_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "NP",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "NP5HSW",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "SC1",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "TT2",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "YJN1",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "2": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "5": {
        "glove": [
          "finger7_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "bigLacePinky_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat",
          "finger4_mat",
          "finger5_mat",
          "finger6_mat",
          "bigLacePinky_mat"
        ]
      },
      "8": {
        "glove": [
          "finger2_mat",
          "finger4_mat",
          "finger6_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "trim_mat",
        "trim_narrowFit_mat",
        "bigLace_mat",
        "bigLacePinky_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "weltingBack_mat",
        "weltingPalm_mat"
      ]
    }
  },
  {
    "id": "YPT110",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger7_mat",
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger7_mat",
        "trim_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      }
    }
  },
  {
    "id": "125KR",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "125SB",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "125FS",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "bigLace_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "bigLace_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "566",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "568",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "715SB",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger10_mat",
          "trim_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger2_mat",
        "finger3_mat",
        "finger4_mat",
        "finger5_mat",
        "finger6_mat",
        "finger7_mat",
        "finger8_mat",
        "finger9_mat",
        "finger10_mat",
        "trim_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat",
        "trim_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "CMHCB2",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "DCT",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "DCTHSW",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "FM16",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingPalm"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      }
    }
  },
  {
    "id": "FM18",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": true,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "web": [
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "trim_mat",
        "trim_narrowFit_mat",
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingPalm"
        ],
        "web": [
          "back",
          "back_OvalR"
        ]
      }
    }
  },
  {
    "id": "FM19",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": true,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "web": [
          "trim_mat",
          "trim_narrowFit_mat"
        ]
      },
      "allOnWeb": [
        "trim_mat",
        "trim_narrowFit_mat",
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "FM20XL",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "iFingerPad_stitching_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "iFingerPad_stitching_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "SC5",
    "snapshots": false,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "FM19SB",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": false,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "iFingerPad_stitching_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "iFingerPad_stitching_mat",
        "trim_stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "TM8SB",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "CM33",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": true,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_OvalR_mat",
          "trim_stitching_mat",
          "trim_OvalR_stitching_mat",
          "embroideryOvalR_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_OvalR_mat",
        "trim_stitching_mat",
        "trim_OvalR_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat",
        "iFingerPad_stitching_mat",
        "iFingerHood_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "bigLace_mat",
        "bigLacePinky_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "deco": {
        "glove": [
          "shellBack_decoMesh",
          "shellBack_decoMesh_backEdge",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_stitching"
        ]
      }
    }
  },
  {
    "id": "CM43HSW",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "CM41",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "JP20",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": false,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": true,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": false,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "bigLace_mat",
        "bigLacePinky_mat"
      ],
      "leather": {
        "web": [
          "back",
          "back_OvalR"
        ]
      },
      "deco": {
        "glove": [
          "shellBack_decoMesh",
          "shellBack_decoMesh_extra_mesh",
          "shellBack_decoMesh_OvalR",
          "shellBack_decoMesh_OvalR_extra_mesh"
        ],
        "web": [
          "decoMesh",
          "decoMesh_stitching"
        ]
      }
    }
  },
  {
    "id": "PL31",
    "snapshots": true,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": false,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": true,
    "hasSweatband": false,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": true,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": true,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBackLaces_mat",
        "shellBackLacesEdge_mat"
      ]
    }
  },
  {
    "id": "SP13",
    "snapshots": false,
    "hasWeltingBack": false,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": false,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "CM33FP",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": true,
    "hasEmbroideryLogo": false,
    "hasMLB": false,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": false,
    "hasSeparatedStitching": false,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": false,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat",
          "trim_narrowFit_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_narrowFit_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ]
    }
  },
  {
    "id": "200TR",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": true,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": false,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "finger1_mat",
          "finger4_mat",
          "trim_mat",
          "trim_stitching_mat"
        ]
      },
      "allOnGlove": [
        "finger1_mat",
        "finger4_mat",
        "trim_mat",
        "shellBack_stitching_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ],
      "allOnWeb": [
        "back_mat",
        "back_OvalR_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat"
      ],
      "leather": {
        "glove": [
          "weltingBack",
          "weltingPalm"
        ]
      }
    }
  },
  {
    "id": "FL12TR",
    "snapshots": true,
    "hasWeltingBack": true,
    "hasWeltingPalm": false,
    "hasWeltingOnWeb": false,
    "hasPatch": true,
    "hasFastback": false,
    "hasLiteToe": false,
    "hasEmbroideryLogo": true,
    "hasMLB": true,
    "hasR2G": true,
    "hasPalmPadLeather": true,
    "hasFingerPadHood": true,
    "hasSweatband": true,
    "hasSeparatedStitching": true,
    "hasTrapezeStamping": false,
    "hasShellBackLaces": false,
    "hasTrimOnWeb": false,
    "hasTrimStitching": true,
    "hasBackPalmStitching": false,
    "hasBackLeftStitching": false,
    "hasBigLaceStitching": false,
    "hasDecoProOnWeb": true,
    "trim": {
      "1": {
        "glove": [
          "trim_mat"
        ]
      },
      "5": {
        "glove": [
          "finger5_mat",
          "trim_mat",
          "trim_narrowFit_mat",
          "embroideryOvalR_stitching_mat",
          "stitching_mat"
        ],
        "web": [
          "back_mat",
          "back_OvalR_mat"
        ]
      },
      "6": {
        "glove": [
          "finger2_mat"
        ]
      },
      "7": {
        "glove": [
          "finger2_mat",
          "finger3_mat"
        ]
      },
      "allOnGlove": [
        "trim_mat",
        "trim_stitching_mat",
        "embroideryOvalR_stitching_mat",
        "stitching_mat"
      ]
    },
    "shellBack": {
      "materials": [
        "shellBack_mat",
        "shellBack_OvalR_mat"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveModelRow[]>> = data;

export type GloveModel = typeof typed[number];

export const GLOVE_MODEL_INDEX_KEY = "id";
export type GloveModelIndexKey = "id";
export type GloveModelId = GloveModel["id"];

let i = 0;
export const GLOVE_MODEL_DICT = {
  "882": typed[i++],
  "REV204": typed[i++],
  "200CV": typed[i++],
  "204HSW": typed[i++],
  "205FS": typed[i++],
  "200WT": typed[i++],
  "200FB": typed[i++],
  "217": typed[i++],
  "302CV": typed[i++],
  "302FB": typed[i++],
  "303": typed[i++],
  "3039HSW": typed[i++],
  "3039FS": typed[i++],
  "REV3039": typed[i++],
  "315": typed[i++],
  "314HSW": typed[i++],
  "3319": typed[i++],
  "502": typed[i++],
  "504": typed[i++],
  "600": typed[i++],
  "6019HSW": typed[i++],
  "6019FS": typed[i++],
  "JD": typed[i++],
  "NP": typed[i++],
  "NP5HSW": typed[i++],
  "SC1": typed[i++],
  "TT2": typed[i++],
  "YJN1": typed[i++],
  "YPT110": typed[i++],
  "125KR": typed[i++],
  "125SB": typed[i++],
  "125FS": typed[i++],
  "566": typed[i++],
  "568": typed[i++],
  "715SB": typed[i++],
  "CMHCB2": typed[i++],
  "DCT": typed[i++],
  "DCTHSW": typed[i++],
  "FM16": typed[i++],
  "FM18": typed[i++],
  "FM19": typed[i++],
  "FM20XL": typed[i++],
  "SC5": typed[i++],
  "FM19SB": typed[i++],
  "TM8SB": typed[i++],
  "CM33": typed[i++],
  "CM43HSW": typed[i++],
  "CM41": typed[i++],
  "JP20": typed[i++],
  "PL31": typed[i++],
  "SP13": typed[i++],
  "CM33FP": typed[i++],
  "200TR": typed[i++],
  "FL12TR": typed[i++]
} as const;

export { typed as GLOVE_MODELS };
